import * as React from 'react';
import { gql } from '@urql/core';
import queryString from 'query-string';
import { PageProps } from 'gatsby';
import { useDynamicCategoryPageQuery } from 'generated/graphql';
import CategoryPage from 'templates/category';
import NotFoundPage from 'pages/404';

const DynamicCategoryPage = ({ location }: PageProps) => {
  const queryParams = queryString.parse(location.search);
  const id = typeof queryParams.id === 'string' ? parseInt(queryParams.id, 10) : 0;
  const [res] = useDynamicCategoryPageQuery({
    variables: { id },
  });

  if (res.error) {
    return <div>{res.error.message}</div>;
  }

  if (res.fetching || !res.data) {
    return <div>Загрузка...</div>;
  }

  if (!res.data.category) {
    return <NotFoundPage />;
  }

  const pageContext = { menuCategories: res.data.menuCategories };

  return <CategoryPage data={res.data} pageContext={pageContext} />;
};

gql`
  query DynamicCategoryPageQuery($id: Int!) {
    ...CategoryPage_data

    menuCategories {
      id
      name
    }
  }
`;

export default DynamicCategoryPage;
